/* ===================== SideBar ======================*/

aside .widget {
	margin-bottom: 30px;
	padding: 35px 25px 35px 25px;
	border: 2px solid #f5f8fa;
	overflow: hidden;
}

aside .widget:last-child {
	margin-bottom: 0
}

.widget-title h4 {
	font-size: 24px;
	margin-bottom: 0;
	line-height: 1;
	color: #1c2045;
	font-weight: 600
}

.slidebar__form {
	position: relative;
}

.slidebar__form input {
	background: #f5f8fa;
	border: none;
	width: 100%;
	padding: 18px 30px;
}

.slidebar__form button {
	position: absolute;
	right: 0;
	top: 0;
	border: none;
	background: #7052fb none repeat scroll 0 0;
	padding: 18px 23px;
	color: #ffffff;
	font-size: 14px;
	cursor: pointer;
	height: 100%;
}

.widget-insta-post li {
	display: inline-block;
	margin: 5px 3px;
	width:23%
}

.widget-social a {
	height: 40px;
	width: 40px;
	line-height: 40px;
	border-radius: 50%;
	display: inline-block;
	background: transparent;
	border: 1px solid #7052fb;
	color: #7052fb;
	margin: 0 3px;
}

.widget-social a:hover {
	background: #7052fb;
	color: #ffffff;
	border-color: #7052fb;
}

.cat__list li {
	padding-bottom: 10px;
	border-bottom: 1px solid #e4e4e4;
	margin-bottom: 10px;
}

.cat__list li:last-child {
	border: medium none
}

.cat__list li a {
	color: #1c2045;
}

.cat__list li a:hover,
.cat__list li a span:hover {
	color: #7052fb
}

.cat__list li a span {
	float: right;
	display: block;
}

.widget__post ul li {
	margin-bottom: 20px;
	overflow: hidden;
}

.widget__post-thumb {
	float: left;
	display: block;
	margin-right: 20px;
	width: 70px
}

.widget__post-content {
	overflow: hidden;
	display: block;
}

.widget__post-content h6 {
	font-size: 16px;
	margin-bottom: 1px;
	padding-right: 15px;
	font-weight: 600;
}

.widget__post-content h6 a {
	color: #1c2045
}

.widget__post-content h6 a:hover {
	color: #7052fb
}

.widget__post-content span {
	font-size: 14px;
}

.widget__post-content span svg {
	margin-right: 8px;
}

.widget__tag ul li {
	display: inline-block;
	margin: 5px 3px;
}

.widget__tag ul li a {
	display: block;
	font-size: 14px;
	color: #111;
	background: #EEF3FA;
	border-radius: 30px;
	padding: 9px 20px;
}

.widget__tag ul li a:hover {
	background: #7052fb;
	color: #fff
}
.widget__post-content svg{
	color: #7052fb;
}