/* ===================== Team ======================*/

.team-section .section-title {
	text-align: center
}

.team-item {
	text-align: center;
	position: relative;
	margin-bottom: 30px;
}

.team-item .image {
	position: relative;
	overflow: hidden;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}

.team-item .image .social {
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	list-style: none;
	z-index: 1;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}

.team-item .image .social li {
	-webkit-transform: translateY(30px);
	transform: translateY(30px);
	-webkit-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	visibility: hidden;
	display: inline-block;
	margin: 0 3px;
}

.team-item .image .social li:last-child {
	margin-bottom: 0;
}

.team-item .image .social li a {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	background-color: #ffffff;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
	text-align: center;
	position: relative;
	font-size: 16px;
	color: #7052fb;
	border-radius: 30px;
	-webkit-transition: 0.6s;
	transition: 0.6s;
}

.team-item .image .social li a i {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	margin-left: auto;
	margin-right: auto;
}

.team-item .image .social li a:hover {
	color: #ffffff;
	background-color: #4b2be3;
}

.team-item .image .social li:nth-child(1) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.team-item .image .social li:nth-child(2) {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.team-item .image .social li:nth-child(3) {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.team-item .image .social li:nth-child(4) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.team-item .image .content {
	position: absolute;
	background: #7052fb;
	box-shadow: 1px 20px 30px rgba(0, 0, 0, 0.07);
	width: 90%;
	bottom: -130px;
	-webkit-transition: 0.6s;
	transition: 0.6s;
	text-align: center;
	padding: 35px 30px 30px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	margin: 0 auto;
	display: block;
	-webkit-transform: translate(5%, 0);
	transform: translate(5%, 0);
}

.team-item .image img {
	border-radius: 20px
}

.team-item .image .content h3 {
	margin-bottom: 0;
	color: #ffffff;
	font-size: 25px;
	font-weight: 600;
	letter-spacing: 1px;
}

.team-item .image .content span {
	display: block;
	color: #ffffff;
	font-size: 16px;
	margin-top: 8px;
}

.team-item:hover .image .social li {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	visibility: visible;
}

.team-item:hover .image .content {
	bottom: 0;
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
	border-radius: 30px 30px 0 0;
}

.team-item:hover .image .social {
	bottom: 105px;
}