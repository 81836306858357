/* ===================== Cart ======================*/

.table-content table {
	background: #ffffff;
	border-color: #eaedff;
	border-radius: 0;
	border-style: solid;
	border-width: 1px 0 0 1px;
	text-align: center;
	width: 100%;
	margin-bottom: 0;
}

.table-content table th {
	border: medium none;
	color: #1c2045;
	text-transform: capitalize;
	font-weight: 600;
	font-size: 14px;
	border-top: 0px !important;
}

.table-content table td.product-name {
	font-size: 16px;
	font-weight: 400;
	text-transform: capitalize;
}

.table-content table td {
	border-top: medium none;
	padding: 20px 10px;
	vertical-align: middle;
	font-size: 16px;
}

.table-content table th,
.table-content table td {
	border-bottom: 1px solid #eaedff;
	border-right: 1px solid #eaedff;
}

.product-quantity input {
	border: none;
	color: #6f7172;
	font-size: 14px;
	font-weight: normal;
	border: 0;
}

.table td,
.table th {
	border-top: 1px solid #eaedff;
}

.product-quantity>input {
	width: 80px;
	border-radius: 3px;
}

.table-content table td.product-subtotal {
	font-size: 16px;
}

.table-content table td .cart-plus-minus {
	float: none;
	margin: 0 auto;
}

.coupon-all {
	margin-top: 50px;
}

.coupon {
	float: left;
	position: relative;
	border: 2px solid #eaedff;
	height: 55px;
	width: 450px;
	border-radius: 30px
}

#coupon_code {
	border: 0px solid #eaedff;
	padding: 0 15px;
	margin-right: 10px;
	position: absolute;
	width: 100%;
	top: 0;
	height: 100%;
	left: 0;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
}

.coupon button {
	position: absolute;
	right: 0;
	top: 0;
	border: medium none;
	cursor: pointer;
	height: 100%;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s
}

.coupon2 {
	float: right;
}

.cart-page-total {
	padding-top: 50px;
}

.cart-page-total>h2 {
	font-size: 25px;
	margin-bottom: 20px;
	text-transform: capitalize;
}

.cart-page-total>ul {
	border: 1px solid #eaedff;
}

.cart-page-total>ul>li {
	list-style: none;
	font-size: 15px;
	color: #1c2045;
	padding: 10px 30px;
	border-bottom: 1px solid #eaedff;
	font-weight: 600;
}

.cart-page-total ul>li>span {
	float: right;
	color: #585858;
	font-weight: 400
}

.cart-page-total li:last-child {
	border-bottom: 0;
}

td.product-thumbnail img {
	width: 125px;
	border-radius: 10px
}

.cart-area h3 {
	color: #1c2045;
	font-weight: 700;
	font-size: 26px;
	margin-bottom: 30px;
	position: relative;
	letter-spacing: 0px;
	display: block;
	text-transform: capitalize;
}

.product-name a,
.product-price,
.amount,
.product-remove a {
	color: #1c2045
}

.product-name a:hover {
	color: #7052fb
}

.product-quantity .num-block {
	float: none
}

.product-quantity .skin-2 .num-in {
	float: none;
	margin: 0 auto
}

.subscribe-box form button {
	position: absolute;
	height: 45px;
	width: auto;
	right: 7px;
	top: 5px;
	background: #7052fb none repeat scroll 0 0;
	color: #fff;
	text-align: center;
	line-height: 35px;
	padding: 0;
	border-radius: 30px;
	border: medium none;
	font-size: 16px;
	cursor: pointer;
	font-weight: 500;
	text-transform: uppercase;
	padding: 5px 20px;
	letter-spacing: 1px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s;
}

button.theme-btn {
	border: medium none;
	cursor: pointer;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s
}

.cart-action {
	text-align: right;
	margin-top: 30px
}