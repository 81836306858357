/* ===================== Product Details ======================*/

.product-details-image {
	text-align: center;
	background: #eef3fa none repeat scroll 0 0;
	border-radius: 5px;
	padding: 30px;
}

.product-details-text h3 {
	margin-bottom: 5px;
	font-size: 40px;
	font-weight: 600;
	color: #1c2045;
	text-transform: capitalize;
}

.tour-rating {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.product-details-text .tour-rating ul {
	display: inline-block;
	margin-right: 10px;
	text-align: left;
}

.product-details-text .tour-rating p {
	display: inline-block;
	margin-left: 5px;
	color: #1c2045;
	font-weight: 500;
	text-transform: capitalize;
}

.tour-rating ul li {
	display: inline-block;
	font-size: 15px;
	margin: 0;
	line-height: 20px;
}

.tour-rating ul li svg {
	color: #ffa11a;
}

.single-pro-page-para {
	margin: 20px 0;
}

.single-shop-price>p {
	font-size: 17px;
	font-weight: 600;
	margin-top: 15px;
	letter-spacing: 0;
	color: #1c2045
}

.single-shop-price p span {
	color: #7052fb;
	margin-left: 10px;
}

.quantity {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	margin-top: 20px
}

.quantity p {
	margin-right: 10px;
	color: #1c2045;
	font-size: 17px;
	font-weight: 600;
	letter-spacing: 0;
}

.num-block {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
}
.num-in input{
	background-color: #eef3fa ;
	border: none;
	width:70px;
	height: 40px;
	text-align: center;
}

.single-shop-page-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-top: 30px;
}

.single-shop-page-btn ul {
	margin-left: 30px;
}

.single-shop-page-btn ul li {
	display: inline-block;
	margin: 0 2px;
}

.single-shop-page-btn ul li a {
	display: block;
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	font-size: 15px;
	color: #7052fb;
	border: 1px solid #7052fb;
}

.related-product-section .section-title {
	text-align: center
}