/* ===================== Subscribe ======================*/

.subscribe-section {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.subscribe-section:after {
	position: absolute;
	content: "";
	z-index: -1;
	left: 0;
	width: 100%;
	height: 100%;
	border-right: 100vw solid #7052fb;
	border-left: 0px solid #7052fb;
	bottom: 0px;
	border-top: 90px solid #eef3fa;
	border-bottom: 0px solid transparent;
}

.innser_subscribe .subscribe-section:after {
	border-right: 100vw solid #7052fb;
	border-left: 0px solid #7052fb;
	border-top: 90px solid #fff;
}

.subscribe-box {
	text-align: center;
	padding-top: 40px;
}

.subscribe-box .section-title h2 {
	position: relative;
	z-index: 1;
	color: #fff;
	text-transform: capitalize;
}

.subscribe-box p {
	color: #fff;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 18px;
	margin: 25px 0;
}

.subscribe-section .section-title {
	margin-bottom: 0
}

.subscribe-box .section-title h2 span:after {
	background-color: #3A1BC6;
}

.subscribe-box form {
	width: 50%;
	margin: 30px auto 0;
	position: relative;
	height: 55px;
	background: #fff;
	border-radius: 30px;
}

.subscribe-box form input {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	border: medium none;
	padding: 10px 20px
}

.subscribe-box form button {
	position: absolute;
	height: 45px;
	width: auto;
	right: 7px;
	top: 5px;
	background: #7052fb none repeat scroll 0 0;
	color: #fff;
	text-align: center;
	line-height: 35px;
	padding: 0;
	border-radius: 30px;
	border: medium none;
	font-size: 16px;
	cursor: pointer;
	font-weight: 500;
	text-transform: uppercase;
	padding: 5px 20px;
	letter-spacing: 1px;
	-webkit-transition: all 0.4s ease 0s;
	transition: all 0.4s ease 0s
}

.subscribe-box form button:hover {
	background: #4b2be3 none repeat scroll 0 0;
	color: #fff;
}
