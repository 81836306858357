/* ===================== PAGE TITLE ======================*/

.breadcrumb-area {
	padding: 190px 0 140px;
	background: #F1EEFF;
	position: relative;
	overflow: hidden;
	width: 100%;
	z-index: 1;
	bottom: -5px;
	margin-top: -110px;
}

.breadcrumb-shape {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 10px;
	background: -webkit-linear-gradient(45deg, #7052fb 30%, #7052fb 100%);
	background: linear-gradient(45deg, #7052fb 30%, #7052fb 100%);
}

.breadcrumb-shape:before {
	left: 0px;
	border-left: 14vw solid #F1EEFF;
	border-right: 0px solid #F1EEFF;
	content: "";
	position: absolute;
	bottom: 0px;
	border-top: 26px solid transparent;
	border-bottom: 0px solid transparent;
	z-index: 1;
	width: 0;
}

.breadcrumb-shape:after {
	right: 0px;
	border-right: 85vw solid #F1EEFF;
	border-left: 0px solid white;
	content: "";
	position: absolute;
	bottom: 0px;
	border-top: 120px solid transparent;
	border-bottom: 0px solid transparent;
	z-index: 1;
	width: 100%;
}

.breadcrumb-area:before {
	left: 0px;
	border-left: 15vw solid #fff;
	border-right: 0px solid white;
	content: "";
	position: absolute;
	bottom: 0px;
	border-top: 32px solid transparent;
	border-bottom: 0px solid transparent;
	z-index: 1;
	width: 0;
}

.breadcrumb-area:after {
	right: 0px;
	border-right: 87vw solid #fff;
	border-left: 0px solid white;
	content: "";
	position: absolute;
	bottom: 0px;
	border-top: 114px solid transparent;
	border-bottom: 0px solid transparent;
	z-index: 1;
	width: 100%;
}

.breadcrumb-inn .section-title  h2 {
	color: #fff;
	font-size: 48px;
	font-weight: 700
}

.breadcrumb-inn .section-title  h2 span:after {
	background-color: #3A1BC6;
}

.breadcrumb-inn .section-title {
	margin: 0
}

